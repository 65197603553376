jQuery(document).ready(function($) {
    $('.faqs').each(function(){
        $(this).find('.faqs__item-header').on('click', function(){
            $(this).toggleClass('open');
            $(this).find('.indicator').toggleClass('open');
            $(this).next('.faqs__item-content').slideToggle('slow');
        });
    });
    $('.joblist').each(function(){
        $(this).find('.joblist__item-header').on('click', function(){
            $(this).toggleClass('open');
            $(this).find('.indicator').toggleClass('open');
            $(this).next('.joblist__item-content').slideToggle('slow');
        });
    });    
});