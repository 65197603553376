jQuery(document).ready(function($) {
	
	$('.dynamic-faqs input[name=search]').on('keyup', function() {
		let keyword = $(this).val().trim().toLowerCase();
		
		let result = [];

		if( keyword !== '' )
		{
			let i      = 0;
			let limit  = 10;
			$(dynamic_faqs_search.faqs).each(function(index, value) {
				if(value.title.toLowerCase().indexOf(keyword) > -1 || value.content.toLowerCase().indexOf(keyword) > -1)
				{
					result.push(value);

					i++;

					if( i == limit) {
						return false;
					}
				}
			});
		}

		let html = '';

		$.each(result, function(index, value) {
			html += `<div class="dynamic-faqs__search-result-item"><a href="#" data-id="${value.id}">${value.title}</a></div>`;
		});

		if (html.length > 0 ) {
			$(this).closest('div').find('.dynamic-faqs__search-result').html(html).addClass('show');
		}
	});

	$('.dynamic-faqs').on('click', '.dynamic-faqs__search-result-item a', function(e) {
		e.preventDefault();
		let id = $(this).attr('data-id');

		if($('.dynamic-faqs__item[data-id="' + id + '"]').length) {
			if($('.dynamic-faqs__item[data-id="' + id + '"]:eq(0) .dynamic-faqs__item-header').is(':not(.open)')) {
				$('.dynamic-faqs__item[data-id="' + id + '"]:eq(0) .dynamic-faqs__item-header').trigger('click');
			}

			$([document.documentElement, document.body]).animate({
				scrollTop: ($('.dynamic-faqs__item[data-id="' + id + '"]:eq(0) .dynamic-faqs__item-header').offset().top - 150)
			}, 1000);
			$(this).closest('.dynamic-faqs').find('.dynamic-faqs__search-result').removeClass('show');
		}
	});

	$('.dynamic-faqs').each(function(){
		$(this).find('.dynamic-faqs__item-header').on('click', function(){
			$(this).toggleClass('open');
			$(this).find('.indicator').toggleClass('open');
			$(this).next('.dynamic-faqs__item-content').slideToggle('slow');
		});
	});
});