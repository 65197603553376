jQuery(document).ready(function($) {

		
		// Galery project
		$('.single-project__top-left').each(function(){
			var $slider = $(this).find('.single-project__top-gallery'),
				$slider_items = $slider.find('.single-project__top-gallery-item'),
				$next_btn = $(this).find('.arrow__next'),
				$prev_btn = $(this).find('.arrow__prev');
								
			if ( $slider_items.length > 1 ) {
				$slider.slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					variableWidth: false,
					infinite: true,
					centerMode: false,
					autoplay: true,
					autoplaySpeed: 4000,
					prevArrow: $prev_btn,
					nextArrow: $next_btn,
					// responsive: [
					// 	{
					// 		breakpoint: 576, 
					// 		settings: {
					// 			dots: true,
					// 			arrows: false
					// 		}
					// 	}
					// ]
				});
			}
		});     

});
	