jQuery(function ($) {
  var page_loading_more = null;
  var loader = $('.loader');
  
  let load_more_btn = $('.project-archive__container .page-load-more__button');

  load_more_btn.on('click', function (e) {
    e.preventDefault();

    loader.addClass('loaderVisible');
    load_more_btn.addClass('btnHidden');

    if (page_loading_more) {
      return false;
    }

    let obj = this;

    page_loading_more = true;
    $(obj).prop('disabled', true);

    let paged = parseInt($(obj).attr('data-paged'));

    let request = {
      action: 'pxs_project_load_more',
      paged: paged,
    };
    $.get(
      $(obj).attr('data-action'),
      request,
      function (response) {
        if (response.last) {
          $(obj).remove();
        } else {
          page_loading_more = false;
          load_more_btn.removeClass('btnHidden');
          $(obj).attr('data-paged', paged + 1);
          $(obj).prop('disabled', false);
        }

        loader.removeClass('loaderVisible');
        $('.project-archive__wrapper').append(response.html);
      },
      'json'
    );
  });
});
