jQuery(document).ready(function($) {
    $('.two-boxes').each(function(){
        $(this).find('.two-boxes__title').matchHeight();      
    });
    // $('.recent-projects__card-wrapper').each(function(){
    //     $(this).find('.recent-projects__card-title').matchHeight();      
    // }); 
    
    // $('.news__card-wrapper').each(function(){
    //     $(this).find('.news__card-title').matchHeight();      
    // }); 

    // $('.news-archive__wrapper').each(function(){
    //     $(this).find('.news__card-title').matchHeight();      
    // }); 
   

});