jQuery(document).ready(function($) {
  //add back button on children menu
  $('#mega-menu-mobile-menu').find('.mega-sub-menu').each(function() {
    $(this).prepend('<li class="mega-menu-item__back"><a href="#">Back</a></li>');
  });

  // add function to back button
  $(document).on('click', '.mega-menu-item__back', function() {
    $(this)
      .closest('.mega-menu-item-has-children')
      .removeClass('mega-toggle-on'); 
  });

  //open sub-menu instead of open the parent-link
  $('#mega-menu-mobile-menu').find('.mega-menu-item-has-children').each(function() {
    $(this).children('.mega-menu-link').on('click', function(e) {
      e.preventDefault();
      $(this).parents('#mega-menu-mobile-menu').addClass('child-active');
    });
  });
  

});
