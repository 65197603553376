jQuery(document).ready(function($) {

	$('.data-sheets').each(function(){

		$(this).find('#datasheet_select').on('change',function(){
			$selected_slug = $(this).children("option:selected").val();
			$target = $('.data-sheets__category-title#' + $selected_slug + ':eq(0)');

			if($target.length) {

				if($target.is(':not(.open)')) {
					$target.trigger('click');
				}
	
				$([document.documentElement, document.body]).animate({
					scrollTop: $target.offset().top - 150
				}, 1000);

			}

		});


		$(this).find('.data-sheets__category-title-wrapper').on('click', function(){
			$(this).toggleClass('open');
			$(this).find('.indicator').toggleClass('open');
			$(this).next('.data-sheets__sub-categories-wrapper').slideToggle('slow');
		});
	});
});