$(function () {
  var arrowsOn = true;
  $('section.projects').each(function () {
    $slider = $(this).find('.projects__lists');
    $nextArrow = $(this).find('.projects__next');
    $prevArrow = $(this).find('.projects__prev');
    $slider.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: arrowsOn,
      prevArrow: $prevArrow,
      nextArrow: $nextArrow,
      variableWidth: true,
      // autoplay: true,
      responsive: [
        {
          breakpoint: 576,
          slidesToShow: 1,
        },
      ],
    });
  });
});
