$(document).ready(function () {
  $('#testimonial__slides').on('init', function(event, slick){
    // console.log("initialized");
    $('.row--testimonial-footer').css('display','flex');
  });  
  $('#testimonial__slides').slick({
    dots: false,
    arrows: true,
    prevArrow: $('.testimonial__prev'),
    nextArrow: $('.testimonial__next'),
  });
  $('#testimonial__slides').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    var current = $(slick.$slides[currentSlide]);
    current.html(current.html());
  }); 
});
