jQuery(document).ready(function($) {
    $('.ctabtn__search-button').on('click',function(e){
        e.preventDefault();
        $('.main-header__search-wrapper').addClass('open');
        e.stopPropagation();
    });
    $(".main-header__search-wrapper").on('click', function(e){
        e.stopPropagation();
    });
    
    $(document).on('click', function(){
        $(".main-header__search-wrapper").removeClass('open');
    });

});